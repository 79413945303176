import React from 'react'
import {
  Container,
  Text,
  _SIZES as SIZES,
  _VARIANTS as VARIANTS,
} from './TextButton.styles'

interface TextButtonProps {
  children: React.ReactNode
  variant?: string
  size?: string
  textColor?: string
  loading?: boolean
  hoverTextColor?: string
  testId?: string
  onClick?: () => void
}

function TextButton({
  children,
  variant = VARIANTS.primary,
  size = SIZES.lg,
  textColor,
  loading,
  hoverTextColor,
  testId,
  onClick,
  ...otherProps
}: TextButtonProps) {
  return (
    <Container
      onClick={onClick}
      $size={size}
      data-testid={testId}
      {...otherProps}
    >
      <Text
        $variant={variant}
        $size={size}
        $color={textColor}
        $hoverColor={hoverTextColor}
      >
        {loading ? <div className="done-spinner" /> : children}
      </Text>
    </Container>
  )
}

export default TextButton
export { SIZES, VARIANTS }
