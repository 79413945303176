import { createContext, useContext } from 'react'

export type UserContextValue = {
  username: string
  sessionToken: string
  isExistingUser: boolean
  unifiedUid: string
  suggestedUnifiedUid: string
}
export const UserContext = createContext<UserContextValue | null>(null)

export const useUser = () => {
  const user = useContext(UserContext)

  if (!user) {
    throw new Error('User not found in UserProvider')
  }

  return user
}

export const withUser = (Component: any) =>
  function withUserComp(props: any) {
    const user = useUser()
    return <Component {...props} user={user} />
  }
